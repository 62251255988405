import { Action } from 'redux';
import { Operation } from 'types';

export const UPDATE_OPS = '@@ops/UPDATE_OPS';
export type T_UPDATE_OPS = typeof UPDATE_OPS;

export interface UpdateOperation extends Action {
	type: T_UPDATE_OPS;
	operation: Operation;
}

export const updateOperation = (operation: Operation): UpdateOperation => {
	return {
		type: UPDATE_OPS,
		operation,
	};
};

export const PERFORM_OPS_ONCE = '@@ops/PERFORM_OPS_ONCE';
export type T_PERFORM_OPS_ONCE = typeof PERFORM_OPS_ONCE;

export interface PerformOperationOnce extends Action {
	type: T_PERFORM_OPS_ONCE;
	key: string;
	action: Action;
	performNow: boolean;
}

export const performOperationOnce = (key: string, action: Action, performNow: boolean = false): PerformOperationOnce => {
	return {
		type: PERFORM_OPS_ONCE,
		key,
		action,
		performNow,
	};
};

export const PERFORM_OPS_ONCE_SESSION = '@@ops/PERFORM_OPS_ONCE_SESSION';
export type T_PERFORM_OPS_ONCE_SESSION = typeof PERFORM_OPS_ONCE_SESSION;

export interface PerformOperationOnceInSession extends Action {
	type: T_PERFORM_OPS_ONCE_SESSION;
	key: string;
	action: Action;
	performNow?: boolean;
	condition?: () => boolean;
}

export const performOperationOnceInSession = (key: string, action: Action, performNow?: boolean, condition?: () => boolean): PerformOperationOnceInSession => {
	return {
		type: PERFORM_OPS_ONCE_SESSION,
		key,
		action,
		performNow,
		condition,
	};
};

export const PERFORM_OPS_DAILY = '@@ops/PERFORM_OPS_DAILY';
export type T_PERFORM_OPS_DAILY = typeof PERFORM_OPS_DAILY;

export interface PerformOperationDaily extends Action {
	type: T_PERFORM_OPS_DAILY;
	key: string;
	action: Action;
	performNow: boolean;
	condition?: () => boolean;
	exactTime?: boolean;
}

export const performOperationDaily = (
	key: string,
	action: Action,
	performNow: boolean = false,
	condition?: () => boolean,
	exactTime?: boolean
): PerformOperationDaily => {
	return {
		type: PERFORM_OPS_DAILY,
		key,
		action,
		performNow,
		condition,
		exactTime,
	};
};

export const PERFORM_OPS_AT_INT = '@@ops/PERFORM_OPS_AT_INT';
export type T_PERFORM_OPS_AT_INT = typeof PERFORM_OPS_AT_INT;

export interface PerformOperationAtInterval extends Action {
	type: T_PERFORM_OPS_AT_INT;
	key: string;
	interval: number;
	action: Action;
	performNow: boolean;
	condition?: () => boolean;
	exactTime?: boolean;
}

export const performOperationAtInterval = (
	key: string,
	interval: number,
	action: Action | any,
	performNow: boolean = false,
	condition?: () => boolean,
	exactTime?: boolean
): PerformOperationAtInterval => {
	return {
		type: PERFORM_OPS_AT_INT,
		key,
		interval,
		action,
		performNow,
		condition,
		exactTime,
	};
};

export const STOP_OPS = '@@ops/STOP_OPS';
export type T_STOP_OPS = typeof STOP_OPS;

export interface StopOperation extends Action {
	type: T_STOP_OPS;
	key: string;
}

export const stopOperation = (key: string): StopOperation => {
	return {
		type: STOP_OPS,
		key,
	};
};

export const MULTI_ACTION = '@@ops/MULTI_ACTION';
export type T_MULTI_ACTION = typeof MULTI_ACTION;

export interface MultiAction extends Action {
	type: T_MULTI_ACTION;
	actions: Action[];
}

export const multiAction = (actions: Action[]): MultiAction => {
	return {
		type: MULTI_ACTION,
		actions,
	};
};
