import { ConfigsById } from 'types';

export const ConfigDump: ConfigsById = {
	LatestBuildVersion: {
		id: 'LatestBuildVersion',
		value: 543,
		active: true,
		deleted: false,
		publishTime: {
			seconds: 1737903807,
			nanoseconds: 373000000,
		},
		updatedAt: {
			seconds: 1737903807,
			nanoseconds: 373000000,
		},
	},
	LatestBuildVersionv1: {
		active: true,
		updatedAt: {
			seconds: 1738583493,
			nanoseconds: 569000000,
		},
		publishTime: {
			seconds: 1738583493,
			nanoseconds: 569000000,
		},
		deleted: false,
		value: 553,
		id: 'LatestBuildVersionv1',
	},
	LatestBuildVersionSPD: {
		id: 'LatestBuildVersionSPD',
		deleted: false,
		active: true,
		value: 9,
		publishTime: {
			seconds: 1724400896,
			nanoseconds: 696000000,
		},
		updatedAt: {
			seconds: 1724400896,
			nanoseconds: 696000000,
		},
	},
	Announcement: {
		id: 'Announcement',
		createdAt: {
			seconds: 1699364653,
			nanoseconds: 242000000,
		},
		publishTime: {
			seconds: 1699364680,
			nanoseconds: 50000000,
		},
		deleted: false,
		value: {
			title: {
				en: 'Introducing <strong>Immortal Memories!</strong>',
				hi: 'नई सुविधा <strong>Immortal Memories!</strong>',
			},
			version: 6,
			tryUrl: '/chronicles',
			message: {
				en: "Congratulations! In this 'Shri Anandpur Satsang' app, a new feature <strong>Immortal Memories</strong> has been launched. It contains slides depicting formation of Shri Anandpur Dham in chronological order.<br /> <br />'Immortal Memories' can be accessed from left-side menu drawer.",
				hi: "बधाई हो! इस 'Shri Anandpur Satsang' app में एक नया फीचर <strong>Immortal Memories</strong> लॉन्च किया गया है। यह श्री आनन्दपुर धाम की स्थापना को क्रमवार रूप में दर्शाता है।<br /><br />'Immortal Memories' को बाईं ओर के menu drawer से access किया जा सकता है।",
			},
		},
		active: false,
		updatedAt: {
			seconds: 1737361025,
			nanoseconds: 577000000,
		},
	},
	SSN: {
		id: 'SSN',
		publishTime: {
			seconds: 1664716920,
			nanoseconds: 923000000,
		},
		createdAt: {
			seconds: 1664716882,
			nanoseconds: 206000000,
		},
		deleted: false,
		active: true,
		by: '16',
		value: {
			enableAt: {
				seconds: 1696310556,
				nanoseconds: 431000000,
			},
			disableAt: {
				seconds: 1746026571,
				nanoseconds: 927000000,
			},
			maxAllowedPeople: 80,
			maxAllowedDate: {
				seconds: 1731814200,
				nanoseconds: 0,
			},
			minAllowedDate: {
				seconds: 1731785400,
				nanoseconds: 0,
			},
			requestEnabled: false,
		},
		updatedAt: {
			seconds: 1731594354,
			nanoseconds: 421000000,
		},
	},
	AutoDownload: {
		id: 'AutoDownload',
		publishTime: {
			seconds: 1599417000,
			nanoseconds: 0,
		},
		active: true,
		createdAt: {
			seconds: 1599417000,
			nanoseconds: 0,
		},
		deleted: false,
		value: [
			'DKPiv1j10dStc3mx0xFO',
			'c3qWKDBuyrgoanYfWcE2',
			'YSfmZYXFwN9OBCjkxmpE',
			'PO2gU8SZbbq40mEKXDdN',
			'UDd3MOAAftvjTNaldx0b',
			'zttqoZ8uXivTFmv7WjR5',
			'JSAbPtmmAoMIZrMiEYKL',
			'v8X3COp68cPdPWQs0DjI',
			'POwDvI5aY7qAPNjORfIG',
			'hKxuvxDxigC7RDJ0KvRi',
			'54RFjG1n2ijUVijHfQme',
		],
		updatedAt: {
			seconds: 1735748340,
			nanoseconds: 637000000,
		},
	},
	Calendar2024: {
		active: true,
		value: {
			hi: {
				'1': 'monthly/2024/JANUARY.JPG',
				'2': 'monthly/2024/FEBRUARY.JPG',
				'3': 'monthly/2024/MARCH.JPG',
				'4': 'monthly/2024/APRIL.JPG',
				'5': 'monthly/2024/MAY.JPG',
				'6': 'monthly/2024/JUNE.JPG',
				'7': 'monthly/2024/JULY.JPG',
				'8': 'monthly/2024/AUGUST.JPG',
				'9': 'monthly/2024/SEPTEMBER.JPG',
				'10': 'monthly/2024/OCTOBER.JPG',
				'11': 'monthly/2024/NOVEMBER.JPG',
				'12': 'monthly/2024/DECEMBER.JPG',
				calendar: 'monthly/2024/calendar_2024.pdf',
			},
		},
		deleted: false,
		updatedAt: {
			seconds: 1700985815,
			nanoseconds: 657000000,
		},
		publishTime: {
			seconds: 1700850600,
			nanoseconds: 422000000,
		},
		createdAt: {
			seconds: 1700850600,
			nanoseconds: 96000000,
		},
		id: 'Calendar2024',
	},
	Calendars: {
		id: 'Calendars',
		publishTime: {
			seconds: 1594571521,
			nanoseconds: 628000000,
		},
		active: true,
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		createdAt: {
			seconds: 1594571522,
			nanoseconds: 258000000,
		},
		value: ['2020', '2019', '2018', '2017', '2021', '2022', '2023', '2024', '2025'],
		updatedAt: {
			seconds: 1734939300,
			nanoseconds: 410000000,
		},
	},
	Festivals2024: {
		deleted: false,
		publishTime: {
			seconds: 1700850600,
			nanoseconds: 583000000,
		},
		value: {
			en: 'festivals/2024/festivals_2024_en.jpg',
			hi: 'festivals/2024/festivals_2024.jpg',
		},
		active: true,
		createdAt: {
			seconds: 1700850600,
			nanoseconds: 784000000,
		},
		updatedAt: {
			seconds: 1700855604,
			nanoseconds: 341000000,
		},
		id: 'Festivals2024',
	},
	Sandesh: {
		value: {
			disableAt: {
				seconds: 1758985722,
				nanoseconds: 574000000,
			},
			enableAt: {
				seconds: 1682917707,
				nanoseconds: 723000000,
			},
			messagingEnabled: false,
		},
		deleted: false,
		createdAt: {
			seconds: 1664723269,
			nanoseconds: 690000000,
		},
		updatedAt: {
			seconds: 1697281930,
			nanoseconds: 509000000,
		},
		active: true,
		publishTIme: {
			seconds: 1664723250,
			nanoseconds: 590000000,
		},
		id: 'Sandesh',
	},
	Snapshot: {
		id: 'Snapshot',
		uri: 'Snapshot - 1738589932628.json',
		timestamp: 1738589927811,
		active: true,
		deleted: false,
		publishTime: {
			_seconds: 1738592557,
			_nanoseconds: 626000000,
		},
		updatedAt: {
			_seconds: 1738592557,
			_nanoseconds: 626000000,
		},
	},
	Calendar2023: {
		updatedAt: {
			seconds: 1672079683,
			nanoseconds: 929000000,
		},
		publishTime: {
			seconds: 1640995200,
			nanoseconds: 0,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		createdAt: {
			seconds: 1672079683,
			nanoseconds: 929000000,
		},
		active: true,
		value: {
			hi: {
				'1': 'monthly/2023/JANUARY.JPG',
				'2': 'monthly/2023/FEBRUARY.JPG',
				'3': 'monthly/2023/MARCH.JPG',
				'4': 'monthly/2023/APRIL.JPG',
				'5': 'monthly/2023/MAY.JPG',
				'6': 'monthly/2023/JUNE.JPG',
				'7': 'monthly/2023/JULY.JPG',
				'8': 'monthly/2023/AUGUST.JPG',
				'9': 'monthly/2023/SEPTEMBER.JPG',
				'10': 'monthly/2023/OCTOBER.JPG',
				'11': 'monthly/2023/NOVEMBER.JPG',
				'12': 'monthly/2023/DECEMBER.JPG',
				calendar: 'monthly/2023/Calendar_2023.pdf',
			},
		},
		deleted: false,
		id: 'Calendar2023',
	},
	Festivals2023: {
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		deleted: false,
		active: true,
		createdAt: {
			seconds: 1672079363,
			nanoseconds: 171000000,
		},
		value: {
			hi: 'festivals/2023/festivals_2023.jpg',
			en: 'festivals/2023/festivals_2023_en.jpg',
		},
		updatedAt: {
			seconds: 1672079363,
			nanoseconds: 171000000,
		},
		publishTime: {
			seconds: 1640995200,
			nanoseconds: 0,
		},
		id: 'Festivals2023',
	},
	Festivals2022: {
		createdAt: {
			seconds: 1640245372,
			nanoseconds: 952000000,
		},
		deleted: false,
		value: {
			en: 'festivals/2022/festivals_2022_en.jpg',
			hi: 'festivals/2022/festivals_2022.jpg',
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		active: true,
		publishTime: {
			seconds: 1483228800,
			nanoseconds: 0,
		},
		updatedAt: {
			seconds: 1640245372,
			nanoseconds: 952000000,
		},
		id: 'Festivals2022',
	},
	Calendar2022: {
		createdAt: {
			seconds: 1640245128,
			nanoseconds: 562000000,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		updatedAt: {
			seconds: 1640245128,
			nanoseconds: 562000000,
		},
		publishTime: {
			seconds: 1609459200,
			nanoseconds: 0,
		},
		active: true,
		deleted: false,
		value: {
			hi: {
				'1': 'monthly/2022/JANUARY.jpg',
				'2': 'monthly/2022/FEBRUARY.jpg',
				'3': 'monthly/2022/MARCH.jpg',
				'4': 'monthly/2022/APRIL.jpg',
				'5': 'monthly/2022/MAY.jpg',
				'6': 'monthly/2022/JUNE.jpg',
				'7': 'monthly/2022/JULY.jpg',
				'8': 'monthly/2022/AUGUST.jpg',
				'9': 'monthly/2022/SEPTEMBER.jpg',
				'10': 'monthly/2022/OCTOBER.jpg',
				'11': 'monthly/2022/NOVEMBER.jpg',
				'12': 'monthly/2022/DECEMBER.jpg',
				calendar: 'monthly/2022/Calendar_2022.pdf',
			},
		},
		id: 'Calendar2022',
	},
	SPDIntroductionBooklet: {
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		publishTime: {
			seconds: 1609459200,
			nanoseconds: 0,
		},
		updatedAt: {
			seconds: 1610264340,
			nanoseconds: 278000000,
		},
		createdAt: {
			seconds: 1610264340,
			nanoseconds: 278000000,
		},
		active: true,
		value: 'IRXPRWKyvzo69q0Qc6X7',
		deleted: false,
		id: 'SPDIntroductionBooklet',
	},
	SPDPrivacy: {
		createdAt: {
			seconds: 1610260654,
			nanoseconds: 784000000,
		},
		active: true,
		updatedAt: {
			seconds: 1610260654,
			nanoseconds: 784000000,
		},
		value: 'AK2RtPRmvVaGRXt09ZNb',
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		publishTime: {
			seconds: 1609459200,
			nanoseconds: 0,
		},
		deleted: false,
		id: 'SPDPrivacy',
	},
	SPDTerms: {
		publishTime: {
			seconds: 1609459200,
			nanoseconds: 0,
		},
		updatedAt: {
			seconds: 1610259578,
			nanoseconds: 373000000,
		},
		active: true,
		value: 'sXKddAzZ9yBdaZ0mNnh2',
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		createdAt: {
			seconds: 1610259578,
			nanoseconds: 373000000,
		},
		id: 'SPDTerms',
	},
	SPDPostalContact: {
		publishTime: {
			seconds: 1609459200,
			nanoseconds: 0,
		},
		value: '<div><div><strong>Shri Prayagdham</strong></div><div>P.O. Shri Prayagdham</div><div>Dist. Pune - 412202</div><div>Maharashtra</div><div>India</div></div>',
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		active: true,
		deleted: false,
		createdAt: {
			seconds: 1610258391,
			nanoseconds: 100000000,
		},
		updatedAt: {
			seconds: 1610258391,
			nanoseconds: 100000000,
		},
		id: 'SPDPostalContact',
	},
	Festivals2018: {
		deleted: false,
		updatedAt: {
			seconds: 1609404368,
			nanoseconds: 0,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		value: {
			en: 'festivals/2018/festivals_2018_en.jpg',
			hi: 'festivals/2018/festivals_2018.jpg',
		},
		publishTime: {
			seconds: 1514764800,
			nanoseconds: 0,
		},
		createdAt: {
			seconds: 1606566908,
			nanoseconds: 837000000,
		},
		active: true,
		id: 'Festivals2018',
	},
	Festivals2017: {
		value: {
			en: 'festivals/2017/festivals_en.jpg',
			hi: 'festivals/2017/festivals.jpg',
		},
		createdAt: {
			seconds: 1606566964,
			nanoseconds: 471000000,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		publishTime: {
			seconds: 1483228800,
			nanoseconds: 0,
		},
		active: true,
		deleted: false,
		updatedAt: {
			seconds: 1609404364,
			nanoseconds: 0,
		},
		id: 'Festivals2017',
	},
	Calendar2018: {
		updatedAt: {
			seconds: 1609404060,
			nanoseconds: 0,
		},
		active: true,
		value: {
			hi: {
				'1': 'monthly/2018/JANUARY_2018.jpg',
				'2': 'monthly/2018/FEBRUARY_2018.jpg',
				'3': 'monthly/2018/MARCH_2018.jpg',
				'4': 'monthly/2018/APRIL_2018.jpg',
				'5': 'monthly/2018/MAY_2018.jpg',
				'6': 'monthly/2018/JUNE_2018.jpg',
				'7': 'monthly/2018/JULY_2018.jpg',
				'8': 'monthly/2018/AUGUST_2018.jpg',
				'9': 'monthly/2018/SEPTEMBER_2018.jpg',
				'10': 'monthly/2018/OCTOBER_2018.jpg',
				'11': 'monthly/2018/NOVEMBER_2018.jpg',
				'12': 'monthly/2018/DECEMBER_2018.jpg',
				calendar: 'monthly/2018/Calendar_2018.pdf',
			},
		},
		publishTime: {
			seconds: 1514764800,
			nanoseconds: 0,
		},
		deleted: false,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		createdAt: {
			seconds: 1606567402,
			nanoseconds: 588000000,
		},
		id: 'Calendar2018',
	},
	Calendar2019: {
		publishTime: {
			seconds: 1546300800,
			nanoseconds: 0,
		},
		createdAt: {
			seconds: 1606567343,
			nanoseconds: 436000000,
		},
		value: {
			hi: {
				'1': 'monthly/2019/JANUARY_2019.jpg',
				'2': 'monthly/2019/FEBRUARY_2_2019.jpg',
				'3': 'monthly/2019/MARCH_2019.jpg',
				'4': 'monthly/2019/APRIL_2019.jpg',
				'5': 'monthly/2019/MAY_2019.jpg',
				'6': 'monthly/2019/JUNE_2019.jpg',
				'7': 'monthly/2019/JULY_2019.jpg',
				'8': 'monthly/2019/AUGUST_2019.jpg',
				'9': 'monthly/2019/SEPTEMBER_2019.jpg',
				'10': 'monthly/2019/OCTOBER_2019.jpg',
				'11': 'monthly/2019/NOVEMBER_2019.jpg',
				'12': 'monthly/2019/DECEMBER_2019.jpg',
				calendar: 'monthly/2019/Calendar_2019.pdf',
			},
		},
		deleted: false,
		updatedAt: {
			seconds: 1609404023,
			nanoseconds: 0,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		active: true,
		id: 'Calendar2019',
	},
	Calendar2020: {
		publishTime: {
			seconds: 1594571520,
			nanoseconds: 855000000,
		},
		updatedAt: {
			seconds: 1609404002,
			nanoseconds: 0,
		},
		value: {
			hi: {
				'1': 'monthly/2020/JANUARY_2020.jpg',
				'2': 'monthly/2020/FEBRUARY_2020.jpg',
				'3': 'monthly/2020/MARCH_2020.jpg',
				'4': 'monthly/2020/APRIL_2020.jpg',
				'5': 'monthly/2020/MAY_2020.jpg',
				'6': 'monthly/2020/JUNE_2020.jpg',
				'7': 'monthly/2020/JULY_2020.jpg',
				'8': 'monthly/2020/AUGUST_2020.jpg',
				'9': 'monthly/2020/SEPTEMBER_2020.jpg',
				'10': 'monthly/2020/OCTOBER_2020.jpg',
				'11': 'monthly/2020/NOVEMBER_2020.jpg',
				'12': 'monthly/2020/DECEMBER_2020.jpg',
				calendar: 'monthly/2020/Calendar_2020.pdf',
			},
		},
		active: true,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		deleted: false,
		createdAt: {
			seconds: 1594571522,
			nanoseconds: 31000000,
		},
		id: 'Calendar2020',
	},
	Calendar2021: {
		value: {
			hi: {
				'1': 'monthly/2021/JANUARY.jpg',
				'2': 'monthly/2021/FEBRUARY.jpg',
				'3': 'monthly/2021/MARCH.jpg',
				'4': 'monthly/2021/APRIL.jpg',
				'5': 'monthly/2021/MAY.jpg',
				'6': 'monthly/2021/JUNE.jpg',
				'7': 'monthly/2021/JULY.jpg',
				'8': 'monthly/2021/AUGUST.jpg',
				'9': 'monthly/2021/SEPTEMBER.jpg',
				'10': 'monthly/2021/OCTOBER.jpg',
				'11': 'monthly/2021/NOVEMBER.jpg',
				'12': 'monthly/2021/DECEMBER.jpg',
				calendar: 'monthly/2021/Calendar_2021.pdf',
			},
		},
		createdAt: {
			seconds: 1609395085,
			nanoseconds: 551000000,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		updatedAt: {
			seconds: 1609403400,
			nanoseconds: 0,
		},
		publishTime: {
			seconds: 1609459200,
			nanoseconds: 0,
		},
		active: true,
		deleted: false,
		id: 'Calendar2021',
	},
	Calendar2017: {
		updatedAt: {
			seconds: 1609400520,
			nanoseconds: 0,
		},
		createdAt: {
			seconds: 1606567470,
			nanoseconds: 252000000,
		},
		deleted: false,
		publishTime: {
			seconds: 1483228800,
			nanoseconds: 0,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		value: {
			hi: {
				'1': 'monthly/2017/JANUARY.jpg',
				'2': 'monthly/2017/FEBRUARY.jpg',
				'3': 'monthly/2017/MARCH.jpg',
				'4': 'monthly/2017/APRIL.jpg',
				'5': 'monthly/2017/MAY.jpg',
				'6': 'monthly/2017/JUNE.jpg',
				'7': 'monthly/2017/JULY.jpg',
				'8': 'monthly/2017/AUGUST.jpg',
				'9': 'monthly/2017/SEPTEMBER.jpg',
				'10': 'monthly/2017/OCTOBER.jpg',
				'11': 'monthly/2017/NOVEMBER.jpg',
				'12': 'monthly/2017/DECEMBER.jpg',
				calendar: 'monthly/2017/Calendar_2017.pdf',
			},
		},
		active: true,
		id: 'Calendar2017',
	},
	Festivals2021: {
		deleted: false,
		publishTime: {
			seconds: 1609353000,
			nanoseconds: 0,
		},
		active: true,
		updatedAt: {
			seconds: 1609394640,
			nanoseconds: 0,
		},
		value: {
			hi: 'festivals/2021/festivals_2021.jpg',
			en: 'festivals/2021/festivals_2021_en.jpg',
		},
		createdAt: {
			seconds: 1609394580,
			nanoseconds: 0,
		},
		id: 'Festivals2021',
	},
	PostalContact: {
		updatedAt: {
			seconds: 1608981900,
			nanoseconds: 0,
		},
		createdAt: {
			seconds: 1606588200,
			nanoseconds: 0,
		},
		value: '<div><div><strong>Shri Anandpur Dham</strong></div><div>P.O. Shri Anandpur</div><div>Dist. Ashok Nagar - 473331 </div><div>Madhya Pradesh</div><div>India</div></div>',
		publishTime: {
			seconds: 1606588200,
			nanoseconds: 0,
		},
		active: true,
		deleted: false,
		id: 'PostalContact',
	},
	Privacy: {
		value: 'z937A6UsBEdDeHqEQjD2',
		deleted: false,
		active: true,
		updatedAt: {
			seconds: 1606740600,
			nanoseconds: 0,
		},
		createdAt: {
			seconds: 1606588200,
			nanoseconds: 0,
		},
		publishTime: {
			seconds: 1529951400,
			nanoseconds: 0,
		},
		id: 'Privacy',
	},
	Terms: {
		active: true,
		value: 'lR9GQKDaDMJ2hhztpij3',
		updatedAt: {
			seconds: 1606650120,
			nanoseconds: 0,
		},
		createdAt: {
			seconds: 1606588200,
			nanoseconds: 0,
		},
		publishTime: {
			seconds: 1529951400,
			nanoseconds: 0,
		},
		deleted: false,
		id: 'Terms',
	},
	Festivals2019: {
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		deleted: false,
		updatedAt: {
			seconds: 1606566746,
			nanoseconds: 908000000,
		},
		value: {
			hi: 'festivals/2019/festivals_2_2019.jpg',
			en: 'festivals/2019/festivals_2_2019_en.jpg',
		},
		createdAt: {
			seconds: 1606566746,
			nanoseconds: 908000000,
		},
		active: true,
		publishTime: {
			seconds: 1546300800,
			nanoseconds: 0,
		},
		id: 'Festivals2019',
	},
	Festivals2020: {
		active: true,
		publishTime: {
			seconds: 1594571521,
			nanoseconds: 524000000,
		},
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		createdAt: {
			seconds: 1594571522,
			nanoseconds: 166000000,
		},
		value: {
			hi: 'festivals/2020/festivals_2020.jpg',
			en: 'festivals/2020/festivals_2020_en.jpg',
		},
		updatedAt: {
			seconds: 1598986862,
			nanoseconds: 0,
		},
		deleted: false,
		id: 'Festivals2020',
	},
	IntroductionBooklet: {
		createdAt: {
			seconds: 1594566752,
			nanoseconds: 68000000,
		},
		updatedAt: {
			seconds: 1594566752,
			nanoseconds: 68000000,
		},
		value: '9WRnYemFk5L8LHwpmCOU',
		deleted: false,
		active: true,
		expireTime: {
			seconds: 253402214400,
			nanoseconds: 0,
		},
		publishTime: {
			seconds: 1594566750,
			nanoseconds: 733000000,
		},
		id: 'IntroductionBooklet',
	},
	WebSnapshot: {
		id: 'WebSnapshot',
		uri: 'WebSnapshot - 1738589928897.json',
		timestamp: 1738589927811,
		active: true,
		deleted: false,
		publishTime: {
			_seconds: 1738592558,
			_nanoseconds: 213000000,
		},
		updatedAt: {
			_seconds: 1738592558,
			_nanoseconds: 213000000,
		},
	},
	WebMergedSnapshot: {
		id: 'WebMergedSnapshot',
		deleted: false,
		active: true,
		uri: 'WebMergedSnapshot - 1723569660832.json',
		timestamp: 1723569655544,
		publishTime: {
			seconds: 1723569793,
			nanoseconds: 67000000,
		},
		updatedAt: {
			seconds: 1723569793,
			nanoseconds: 67000000,
		},
	},
	MergedSnapshot: {
		id: 'MergedSnapshot',
		deleted: false,
		active: true,
		uri: 'MergedSnapshot - 1723569663601.json',
		timestamp: 1723569655544,
		publishTime: {
			seconds: 1723569792,
			nanoseconds: 136000000,
		},
		updatedAt: {
			seconds: 1723569792,
			nanoseconds: 136000000,
		},
	},
	Calendar2025: {
		id: 'Calendar2025',
		active: true,
		deleted: false,
		createdAt: {
			seconds: 1734937349,
			nanoseconds: 253000000,
		},
		publishTime: {
			seconds: 1734937379,
			nanoseconds: 723000000,
		},
		value: {
			hi: {
				'1': 'monthly/2025/v2/JAN.jpg',
				'2': 'monthly/2025/v2/FEB.jpg',
				'3': 'monthly/2025/v2/MAR.jpg',
				'4': 'monthly/2025/v2/APR.jpg',
				'5': 'monthly/2025/v2/MAY.jpg',
				'6': 'monthly/2025/v2/JUN.jpg',
				'7': 'monthly/2025/v2/JUL.jpg',
				'8': 'monthly/2025/v2/AUG.jpg',
				'9': 'monthly/2025/v2/SEP.jpg',
				'10': 'monthly/2025/v2/OCT.jpg',
				'11': 'monthly/2025/v2/NOV.jpg',
				'12': 'monthly/2025/v2/DEC.jpg',
				calendar: 'monthly/2025/v2/calendar_2025.pdf',
			},
		},
		updatedAt: {
			seconds: 1735283265,
			nanoseconds: 459000000,
		},
	},
	Festivals2025: {
		id: 'Festivals2025',
		value: {
			en: 'festivals/2025/festivals_2025_en.jpg',
			hi: 'festivals/2025/festivals_2025.jpg',
		},
		deleted: false,
		active: true,
		createdAt: {
			seconds: 1734937017,
			nanoseconds: 851000000,
		},
		publishTime: {
			seconds: 1734937036,
			nanoseconds: 239000000,
		},
		updatedAt: {
			seconds: 1734937176,
			nanoseconds: 716000000,
		},
	},
	UpgradePolicy: {
		id: 'UpgradePolicy',
		active: true,
		createdAt: {
			seconds: 1735917970,
			nanoseconds: 15000000,
		},
		publishTime: {
			seconds: 1735918252,
			nanoseconds: 31000000,
		},
		value: {
			minAndroidBuild: 60,
			message: {
				en: "'Shri Anandpur Satsang' App needs to be updated. You are using an older version which has been discontinued now. Please update this app so that we can serve you better.",
				hi: "'श्री आनन्दपुर सत्संग' App को update करना आवश्यक है। आप एक पुराने version का उपयोग कर रहे हैं जिसे अब बंद कर दिया गया है। कृपया इस App को अपडेट करें ताकि हम आपको बेहतर सेवा प्रदान कर सकें।",
			},
			minIosBuild: 57,
			policy: 'easy',
		},
		updatedAt: {
			seconds: 1736092020,
			nanoseconds: 502000000,
		},
	},
} as unknown as ConfigsById;
